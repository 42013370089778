<template>
  <div class="page-anim1" v-show="!retryLogin">
    <div class="bg-holder">
      <img
        src="../assets/images/login-bg-mobile.png"
        class="bg desktop-hidden tablet-hidden"
      />
    </div>
    <div class="container">
      <div class="login-holder">
        <img
          class="logo page-anim2 tablet-hidden mobile-hidden"
          src="../assets/images/logo.png"
          alt="cipatex"
        />
        <h1 class="title page-anim2">Já sou cadastrado</h1>
        <div
          @keypress.enter="login"
          class="input-item page-anim2"
          style="margin-top: 0"
        >
          <span>E-mail</span>
          <input type="email" v-model="loginForm.email" />
        </div>
        <div
          @keypress.enter="login"
          class="input-item page-anim2"
          style="margin-top: 0"
        >
          <span>Senha</span>
          <input type="password" v-model="loginForm.password" />
        </div>

        <transition enter-active-class="animated fadeIn">
          <p
            v-show="warningMessage != ''"
            class="warning text-white text-center"
          >
            {{ warningMessage }}
          </p>
        </transition>

        <transition enter-active-class="animated fadeIn">
          <router-link
            to="/recuperar-senha"
            v-show="warningMessage != ''"
            class="warning text-white text-center"
            style="
              position: relative;
              display: block;
              font-weight: bold;
              text-align: center;
            "
          >
            {{ "Esqueceu sua senha?" }}
          </router-link>
        </transition>

        <button
          @click="login"
          class="btn desktop12 tablet12 mobile12 mx-auto page-anim3 login"
        >
          <span v-show="!loading">Entrar</span>
          <div v-show="loading" class="loading2 white"></div>
        </button>

        <div class="flex-between mt-3-desktop bottom">
          <router-link
            to="/home"
            class="page-anim3"
            style="border-bottom: 1px solid #fff"
          >
            Entrar como visitante
          </router-link>
          <router-link
            to="/cadastro"
            class="page-anim3"
            style="border-bottom: 1px solid #fff"
          >
            Criar conta >
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import validators from "../util/validators";
import authentication from "../util/authentication";

export default {
  name: "Login",
  data() {
    return {
      warningMessage: "",
      loading: false,
      retryLogin: false,
      loginForm: {
        email: "",
        password: "",
        code: "",
      },
    };
  },
  created() {
    if (authentication.isLoggedIn()) {
      this.$router.push("/home");
      return;
    }
    if (localStorage.retryL) {
      this.retryLogin = true;
      let user = JSON.parse(localStorage.retryL);
      this.loginForm.email = atob(user.e);
      this.loginForm.password = atob(user.p);
      this.login();
    }
  },
  methods: {
    login() {
      if (!validators.validateEmail(this.loginForm.email)) {
        this.warningMessage =
          "Por favor, verifique se o e-mail digitado está correto.";
        return;
      }
      if (this.loginForm.password == "") {
        this.warningMessage = "Por favor, informe sua senha.";
        return;
      }

      this.warningMessage = "";
      this.loading = true;

      authentication
        .login(this.loginForm)
        .then((response) => {
          if (response) {
            let user = {
              e: btoa(this.loginForm.email),
              p: btoa(this.loginForm.password),
            };
            localStorage.retryL = JSON.stringify(user);
            this.$router.push("/home");
          } else {
            this.warningMessage = "Usuário e/ou senha incorreto(s)";
          }
        })
        .catch((err) => {
          console.log(err);
          this.warningMessage =
            "Algo deu errado. Por favor, tente novamente em alguns instantes.";
        })
        .finally(() => {
          this.retryLogin = false;
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.page-anim1 {
  background-color: #fff;
}

img.bg {
  position: fixed;
  min-width: 100vw;
  height: 100vh;
  top: 0;
  left: 50%;
  filter: brightness(0.6);
  transform: translateX(-50%);
}

img.logo {
  position: relative;
  display: block;
  width: 120px;
  margin: 30px auto;
}

.login-holder {
  position: relative;
  width: 90%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 25px 0;
  margin: 0 auto;
  gap: 3.2rem;
}

.btn.login {
  margin-top: 0;
}

h1.title {
  color: #fff;
  font-weight: lighter;
  letter-spacing: 1px;
}

.input-item {
  input {
    border-color: #fff;
  }
  span {
    color: #fff;
    font-size: 1.2em;
  }
}

.bottom {
  position: relative;
  width: 100%;
  a {
    color: #fff;
    font-family: fontMedium;
    font-size: 1.15em;
    &.create-account {
      color: var(--yellow1);
      @media screen and (min-width: 900px) {
        color: var(--primary);
      }
    }
  }
}

input {
  color: #fff;
}

.warning {
  text-shadow: 1px 1px #000;
  margin: 15px auto;
}

@media screen and (min-width: 900px) {
  .page-anim1 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 3rem;
    justify-content: flex-start;
    position: relative;
    width: 100vw;
    align-items: center;
    background-color: var(--blue1);
  }
  .container {
    margin: initial;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .login-holder {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 8px;
    padding: 45px 15px;
    height: 100%;
  }
  .bg-holder {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    @media screen and (min-width: 900px) {
      background-image: url("../assets/images/login-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
  img.bg {
    position: absolute;
    height: 100vh;
    top: 0;
    left: 50%;
    opacity: 1;
    filter: brightness(0.7);
    transform: translateX(-50%);
    border-radius: 0;
  }
  button.btn.login {
    background-color: var(--primary);
  }
}

@media only screen and (min-width: 721px) and (max-width: 899px) {
  .bg-holder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-image: url("../assets/images/login-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: brightness(0.7);
  }
}
</style>

<template>
  <header :style="`background-color:${background}`">
    <div class="container">
      <div v-show="showBackButton" class="btn-back" @click="goBack">
        <ArrowLeft :fillColor="menuColor" />
      </div>
      <img
        v-show="showLogo"
        src="../assets/images/logo.png"
        alt="Siber"
        class="logo pointer"
        @click="goToHome"
      />

      <h1 v-show="title" :style="`color:${menuColor}`" class="header-title">
        {{ title }}
      </h1>

      <ul
        v-show="showDesktopNavbar"
        class="desktop-nav tablet-hidden mobile-hidden"
      >
        <li>
          <router-link
            to="/home"
            class="create-account"
            :class="{ active: $route.name == 'Home' }"
          >
            <p :style="`color:${menuColor}`">Receitas</p>
          </router-link>
        </li>
        <li>
          <router-link
            to="/produtos"
            class="create-account"
            :class="{ active: $route.name == 'Products' }"
          >
            <p :style="`color:${menuColor}`">Produtos</p>
          </router-link>
        </li>
        <li>
          <router-link
            to="/artigos"
            class="create-account"
            :class="{ active: $route.name == 'Posts' }"
          >
            <p :style="`color:${menuColor}`">Artigos</p>
          </router-link>
        </li>
        <li>
          <router-link
            to="/favoritos"
            class="create-account"
            :class="{ active: $route.name == 'Favorites' }"
          >
            <p :style="`color:${menuColor}`">Favoritos</p>
          </router-link>
        </li>
      </ul>
      <div
        @click.stop="showMenu = !showMenu"
        v-show="showMenuIcon"
        class="btn-menu pointer"
      >
        <div class="line" :style="`background-color: ${menuColor};`"></div>
        <div class="line" :style="`background-color: ${menuColor};`"></div>
        <div class="line" :style="`background-color: ${menuColor};`"></div>
      </div>

      <transition
        enter-active-class="animated zoomIn"
        leave-active-class="animated zoomOut"
      >
        <nav v-show="showMenu" v-click-outside="hideMenu">
          <ul>
            <li>
              <router-link
                to="/onde-comprar"
                class="create-account"
                :class="{ active: $route.name == 'WhereBuy' }"
              >
                <Store />
                <p>Onde Comprar</p>
              </router-link>
            </li>
            <li v-show="userLoggedIn">
              <router-link
                to="/perfil"
                class="create-account"
                :class="{ active: $route.name == 'Profile' }"
              >
                <Account />
                <p>Meu perfil</p>
              </router-link>
            </li>
            <li v-show="userLoggedIn">
              <router-link
                to="/meus-insumos"
                class="create-account"
                :class="{ active: $route.name == 'Ingredients' }"
              >
                <BasketIn />
                <p>Meus insumos</p>
              </router-link>
            </li>
            <li v-show="userLoggedIn">
              <router-link
                to="/minha-receita/nova"
                class="create-account"
                :class="{ active: $route.name == 'RecipeCrud' }"
              >
                <Cutlery />
                <p>Nova receita</p>
              </router-link>
            </li>
            <li>
              <router-link
                to="/contato"
                class="create-account"
                :class="{ active: $route.name == 'Contact' }"
              >
                <Chat />
                <p>Contato</p>
              </router-link>
            </li>
            <li>
              <router-link 
                to="/termos" 
                class="create-account" 
                :class="{active: $route.name == 'Terms'}">
                <Chat />
                <p>Termos de uso</p>
              </router-link>
              <!-- <a
                href="https://siber-app.s3.sa-east-1.amazonaws.com/termos"
                target="_blank"
                class="create-account"
                rel="noopener noreferrer"
                :class="{ active: $route.name == 'Terms' }"
              >
                <File /> 
                
              </a>-->
            </li>
            <li v-show="!userLoggedIn">
              <router-link
                to="/cadastro"
                class="create-account"
                :class="{ active: $route.name == 'SignUp' }"
              >
                <Account />
                <p>Criar conta</p>
              </router-link>
            </li>
            <li v-show="userLoggedIn">
              <a @click="logout" class="create-account pointer">
                <Logout style="transform: scaleX(-1)" />
                <p>Sair</p>
              </a>
            </li>
          </ul>
        </nav>
      </transition>
    </div>
  </header>
</template>

<script>
import authentication from "../util/authentication";
import ArrowLeft from "vue-material-design-icons/ArrowLeft.vue";
import Store from "vue-material-design-icons/Store.vue";
import Account from "vue-material-design-icons/Account.vue";
import BasketIn from "vue-material-design-icons/BasketFill.vue";
import Cutlery from "vue-material-design-icons/SilverwareForkKnife.vue";
import Chat from "vue-material-design-icons/Chat.vue";
import Logout from "vue-material-design-icons/Logout.vue";

export default {
  components: {
    ArrowLeft,
    Store,
    Account,
    BasketIn,
    Cutlery,
    Chat,
    Logout,
  },
  props: {
    background: {
      type: String,
      default: "transparent",
    },
    title: {
      type: String,
      default: "",
    },
    showBackButton: {
      type: Boolean,
      default: true,
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
    showMenuIcon: {
      type: Boolean,
      default: true,
    },
    menuColor: {
      type: String,
      default: "var(--primary)",
    },
    showDesktopNavbar: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      opened: false,
      showMenu: false,
    };
  },
  computed: {
    userLoggedIn() {
      return authentication.isLoggedIn();
    },
  },
  methods: {
    goToHome() {
      if (this.$route.path !== "/home") this.$router.push("/home");
    },
    hideMenu() {
      this.showMenu = false;
    },
    goBack() {
      this.$router.go(-1);
    },
    logout() {
      authentication.logout();
    },
  },
};
</script>
<style lang="scss">
header {
  position: absolute;
  width: 100vw;
  height: 62px;
  top: 0;
  left: 0;
  z-index: 999;

  .container {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.btn-back {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 22px;
  cursor: pointer;

  svg {
    color: #fff;
  }
}

img.logo {
  position: absolute;
  max-height: 70%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.header-title {
  position: absolute;
  font-family: fontLight;
  font-weight: lighter;
  font-size: 1.25em;
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  letter-spacing: 1px;
  z-index: -1;
}

.desktop-nav {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 0 0 25px;

  li {
    position: relative;
    margin-right: 28px;

    a {
      display: flex;
      align-items: center;
      svg {
        margin-right: 12px;
      }
      p {
        font-size: 1.1em;
      }
    }
  }
}

.btn-menu {
  position: relative;
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;

  .line {
    position: relative;
    width: 65%;
    height: 2px;
    &:nth-child(2) {
      position: relative;
      margin: 5px 0;
    }
  }
}

nav {
  position: absolute;
  top: 42px;
  right: 36px;
  width: 200px;
  padding: 15px !important;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);

  ul li {
    position: relative;
    width: 100%;
    height: 44px;

    &:not(:last-child) {
      border-bottom: 1px solid #00000015;
    }

    a {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      p {
        font-size: 1.2em;
        margin-left: 15px !important;
      }

      svg {
        font-size: 1.3em !important;
      }
    }
  }
}

a.active svg,
a.active p {
  color: var(--primary);
}

a.active p {
  font-family: fontBold;
}

@media screen and (min-width: 1160px) {
}
</style>

<template>
  <div class="navbar">
    <router-link class="item" to="/home">
      <Book class="icon" />
      <p>Receitas</p>
    </router-link>
    <router-link class="item" to="/produtos">
      <IceCream class="icon" />
      <p>Produtos</p>
    </router-link>
    <router-link class="item" to="/artigos">
      <Post class="icon" />
      <p>Artigos</p>
    </router-link>
    <router-link v-show="userLoggedIn" class="item" to="/favoritos">
      <Favorite class="icon" />
      <p>Favoritos</p>
    </router-link>
  </div>
</template>
<script>
import authentication from "@/util/authentication";
import Book from "vue-material-design-icons/BookOpenVariant.vue";
import IceCream from "vue-material-design-icons/IceCream.vue";
import Post from "vue-material-design-icons/Post.vue";
import Favorite from "vue-material-design-icons/HeartOutline.vue";

export default {
  components: {
    Book,
    IceCream,
    Post,
    Favorite,
  },
  computed: {
    userLoggedIn() {
      return authentication.isLoggedIn();
    },
  },
};
</script>
<style lang="scss" scoped>
.navbar {
  position: fixed;
  width: 100vw;
  height: 60px;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid #dfdfdf;
  z-index: 999;
  background-color: #fff;
}

.item {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span svg {
    color: var(--dark5);
  }

  p {
    color: var(--dark5);
    font-size: 1em;
  }

  &.active svg,
  &.active p {
    color: var(--primary);
  }
}

.router-link-active * {
  color: var(--secondary) !important;
}

.material-design-icon.icon {
  height: 2em;
  width: 2em;
}

.material-design-icon.icon > .material-design-icon__svg {
  height: 2em;
  width: 2em;
}
</style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import moment from "moment";
import VueMoment from "vue-moment";
Vue.use(VueMoment, { moment });

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
Vue.component("v-select", vSelect);

import VueCurrencyFilter from "vue-currency-filter";
Vue.use(VueCurrencyFilter, {
  symbol: "R$",
  thousandsSeparator: ".",
  fractionCount: 2,
  fractionSeparator: ",",
  symbolPosition: "front",
  symbolSpacing: false,
});

import money from "v-money";
Vue.use(money, { precision: 2, decimal: ",", thousands: ".", prefix: "R$ " });

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
const options = {
  confirmButtonColor: "var(--primary)",
  cancelButtonColor: "#aaa",
};

Vue.use(VueSweetalert2, options);

import infiniteScroll from "vue-infinite-scroll";
Vue.use(infiniteScroll);

import "./assets/css/desktop.css";
import "./assets/css/mobile.css";
import "./assets/css/tablet.css";
import "./assets/css/animate.css";

Vue.config.productionTip = false;

Vue.directive("click-outside", {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his childrens
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

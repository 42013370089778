import axios from "axios";

const endpoint =
  process.env.NODE_ENV === "development"
    ? "https://localhost:44308/api/"
    : "https://api.kerryfoodservicebrands.com/api/";

const http = axios.create({
  baseURL: endpoint,
});

const baseLocalUrl = process.env.VUE_APP_MOBILEMODE === "true" ? "#/" : "/";

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error.response.status);

    if (error.response.status === 401) {
      localStorage.removeItem("user");
      localStorage.retryLogin = "true";
      window.location = baseLocalUrl + "login";
    }

    if (error.response.status === 404) {
      window.location = baseLocalUrl + "404";
    }

    return Promise.reject(error);
  }
);

export default http;

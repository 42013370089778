<template>
  <div>
    <Header :showBackButton="false" class="page-anim1" />

    <section>
      <div class="container">
        <div class="filters">
          <div class="search-item">
            <input
              v-model="searchQuery"
              type="text"
              placeholder="Pesquisar receitas"
            />
            <Magnify class="pointer" @click="search" />
          </div>
          <select @change="search" v-model="selectedCategory">
            <option value="">Categoria: Todas</option>
            <option v-for="cat in categories" :key="cat.id" :value="cat.id">
              {{ cat.name }}
            </option>
          </select>
        </div>

        <div class="title mt-3 tablet-hidden mobile-hidden animated fadeInLeft">
          Receitas mais recentes
        </div>

        <transition enter-active-class="animated zoomIn">
          <div
            v-show="recipes.length > 0"
            class="recipes"
            v-infinite-scroll="getRecipes"
            infinite-scroll-disabled="allRecipesLoaded"
            infinite-scroll-distance="10"
          >
            <RecipeCard
              v-for="recipe in recipes"
              :key="recipe.id"
              :id="recipe.id"
              :title="recipe.title"
              :description="recipe.description"
              :image="recipe.coverImage"
              :likes="recipe.likes"
              :slug="recipe.slug"
            />
          </div>
        </transition>

        <div
          v-show="loading"
          class="flex-center desktop12 tablet12 mobile12 mt-2 mb-2"
        >
          <div class="loading2"></div>
        </div>
      </div>
    </section>

    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div v-show="showTermsAcceptance" class="bg-cover"></div>
    </transition>

    <transition
      enter-active-class="animated zoomIn"
      leave-active-class="animated zoomOut"
    >
      <div v-show="showTermsAcceptance" class="terms-holder">
        <div class="header">
          <IconWarning :size="52" fillColor="var(--secondary)" />
          <h1 class="color-secondary text-center">
            Atualização dos nossos termos
          </h1>
        </div>
        <p class="text-center">
          Os
          <a noreferrer :href="termsLink" target="_blank">termos de uso</a> da
          nossa plataforma foram atualizados. <br /><br />Ao continuar você
          confirma que leu e está de acordo.
        </p>

        <div class="flex-center">
          <div @click="acceptTerms" class="btn">
            <span>Aceitar</span>
          </div>
        </div>
      </div>
    </transition>

    <Navbar v-show="!showTermsAcceptance" class="desktop-hidden" />
  </div>
</template>
<script>
import http from "@/http";
import authentication from "../util/authentication";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import RecipeCard from "../components/RecipeCard";
import Magnify from "vue-material-design-icons/Magnify";
import IconWarning from "vue-material-design-icons/ShieldAlertOutline";

const html = document.querySelector("html");

export default {
  name: "Home",
  components: {
    Header,
    Navbar,
    RecipeCard,
    Magnify,
    IconWarning,
  },
  data() {
    return {
      loading: false,
      selectedCategory: "",
      take: 18,
      page: 1,
      recipes: [],
      categories: [],
      searchQuery: "",
      allRecipesLoaded: false,
      showTermsAcceptance: false,
      termsLink: "",
    };
  },
  created() {
    this.getCategories();
    this.getRecipes();

    if (authentication.isLoggedIn()) this.checkTermsOfUse();
  },
  methods: {
    async acceptTerms() {
      this.showTermsAcceptance = false;

      const response = await http.put(
        "user/acceptTermsOfUse",
        {},
        authentication.getHeaders()
      );
      let user = authentication.getUser();
      user.info = response.data;
      localStorage.user = JSON.stringify(user);
    },
    async checkTermsOfUse() {
      let terms = await http.get("termsOfUse");
      let userAcceptedDate = authentication.getUser()?.info?.dateAcceptTerms;
      if (!terms?.data?.date || !userAcceptedDate) return;

      this.termsLink = terms.data.path;

      if (new Date(terms.data.date) > new Date(userAcceptedDate)) {
        this.showTermsAcceptance = true;
        html.style.overflow = "hidden";
      }
    },
    getRecipes() {
      if (this.loading || this.allRecipesLoaded) return;

      this.loading = true;
      http
        .get(
          `recipe/list?take=${this.take}&page=${this.page}&categoryId=${this.selectedCategory}&search=${this.searchQuery}&orderBy=timestamp`,
          authentication.getHeaders()
        )
        .then((response) => {
          if (this.page < response.data.pages) {
            this.page++;
          } else {
            this.allRecipesLoaded = true;
          }
          this.recipes = [...this.recipes, ...response.data.list];
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      this.recipes = [];
      this.page = 1;
      this.allRecipesLoaded = false;
      this.getRecipes();
    },
    getCategories() {
      http
        .get("recipeCategory/list?take=-1")
        .then((response) => {
          this.categories = response.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.recipes {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 25px;
}

.filters {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  .search-item {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--secondary);
    input {
      position: relative;
      width: calc(100% - 30px);
      font-size: 1.05em;
      &::placeholder {
        font-size: 1.05em;
      }
    }
  }
  select {
    position: relative;
    width: 40%;
    height: 28px;
    color: var(--dark5);
    font-size: 1.05em;
    border-bottom: 1px solid var(--secondary);
  }
}

.terms-holder {
  position: fixed;
  width: 90%;
  max-width: 440px;
  padding: 2.4em;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 0.6em;
  transform: translateY(-50%);
  background-color: #fff;
  box-shadow: 0 10px 25px -1px rgba(0, 0, 0, 0.12);
  z-index: 999;
  .header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5em;
    h1 {
      font-size: 1.6em;
    }
  }
  p {
    font-size: 1.2em;
    margin-top: 25px;
    a {
      font-family: fontBold;
      color: var(--primary);
      font-size: 1em;
    }
  }
}

@media screen and (min-width: 1160px) {
  .recipes {
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  }
}
</style>

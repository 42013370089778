import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home";
import Login from "../views/Login";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/",
    alias: ["/login"],
    name: "Login",
    component: Login,
  },
  {
    path: "/receita/:id",
    name: "Recipe",
    component: () => import("../views/Recipe.vue"),
  },
  {
    path: "/produtos",
    name: "Products",
    component: () => import("../views/ProductList.vue"),
  },
  {
    path: "/produto/:id",
    name: "Product",
    component: () => import("../views/Product.vue"),
  },
  {
    path: "/artigos",
    name: "Posts",
    component: () => import("../views/PostList.vue"),
  },
  {
    path: "/artigo/:id",
    name: "Post",
    component: () => import("../views/Post.vue"),
  },
  {
    path: "/favoritos",
    name: "Favorites",
    component: () => import("../views/Favorites.vue"),
  },
  {
    path: "/perfil",
    name: "Profile",
    component: () => import("../views/Profile.vue"),
  },
  {
    path: "/onde-comprar",
    name: "WhereBuy",
    component: () => import("../views/WhereBuyList.vue"),
  },
  {
    path: "/contato",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/termos",
    name: "Terms",
    component: () => import("../views/Terms.vue"),
  },
  {
    path: "/cadastro",
    name: "SignUp",
    component: () => import("../views/SignUp.vue"),
  },
  {
    path: "/minha-receita/:id",
    name: "RecipeCrud",
    component: () => import("../views/RecipeCrud.vue"),
  },
  {
    path: "/meus-insumos",
    name: "Ingredients",
    component: () => import("../views/Ingredients.vue"),
  },
  {
    path: "/recuperar-senha",
    name: "Recuperar Senha",
    component: () => import("../views/PasswordRecovery.vue"),
  },
  {
    path: "*",
    name: "NotFound",
    component: () => import("../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: process.env.VUE_APP_MOBILEMODE === "true" ? "hash" : "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
